
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import PBreadcrumb from "primevue/breadcrumb";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";

import { addMoneyMask, formatDate, useFilter, usePagination, getBatchSituation } from "@/libs/utils";
import { Batch } from "@/store/batch/types";

const VBatchList = defineComponent({
  name: "VBatchList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, PBreadcrumb, CDropdown },
  setup() {
    useHead({ title: "Lista de lotes de pagamento | obmed" });

    const store = useStore();
    const route = useRoute();
    const { hasFilters } = useFilter();

    const batches = computed(() => store.state.batch.list);
    const loading = reactive({ list: false });

    const breadcrumb = [
      {
        label: "Lotes de pagamento",
        icon: "fas fa-boxes-packing mr-1",
        to: { name: "batch-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "batch",
      setPage: "setBatchPaginationPage",
      updateList: getBatches,
    });

    function getDropdownOptions(batch: Batch) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar guias",
          icon: "fas fa-info-circle",
          to: { name: "batch-details", params: { _id: String(batch.id) } },
        },
      ];

      if (!["CA", "PG"].includes(batch.ie_situacao)) {
        items.push({
          label: "Pagar lote",
          icon: "fas fa-sack-dollar",
          to: { name: "batch-pay", params: { _id: String(batch.id) } },
        });
        items.push({
          label: "Cancelar lote",
          icon: "fas fa-sack-xmark",
          to: { name: "batch-cancel", params: { _id: String(batch.id) } },
        });
      }

      return items;
    }

    function getBatchTotal(batch: Batch) {
      const total = batch.cd_guia_eletronica_item.reduce(
        (total, guide) => total + Number(guide?.cd_guia_eletronica_item.nr_valor),
        0
      );

      return total;
    }

    async function getBatches() {
      loading.list = true;
      await store.dispatch("getBatches");
      loading.list = false;
    }

    getBatches();

    return {
      ...{ batches, pagination, loading, breadcrumb, hasFilters },
      ...{ handleUpdateCurrentPage, getBatches, getDropdownOptions, getBatchTotal },
      ...{ formatDate, addMoneyMask, getBatchSituation },
    };
  },
});

export default VBatchList;
